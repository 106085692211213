import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BookingsActions,
  UserActions,
  CalendarActions,
  ServiceActions,
} from '../../../store/actions';
import { Bookings, BookingsMini, RejectedBookings, RejectedBookingsMini } from './index';
import { AthleteInfo } from '../Dashboard';
import CalendarEntries from '../../../hocs/CalendarEntries';
import { DateSelection } from '../../Popup';
import { getTrainingTypes, ifTrainingsPublished } from '../../../utils/utilities';
import { NewTraining } from '../Services';
import Search from './Search';

let connectProps = {
  ...BookingsActions,
  ...UserActions,
  ...ServiceActions,
  ...CalendarActions,
};

let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
  providedServices: state.Service.service.get('providedServices'),
  providedServicesPagy: state.Service.service.get('providedServicesPagy'),
  buttonLoading: state.User.meta.get('buttonLoading'),
  selectedTrainingType: state.User.meta.get('selectedTrainingType'),
});

let enhancer = connect(connectState, connectProps);

class BookingsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'history',
      searchText: '',
      newTrainingPopup: null,
    };

    this.onSearchTextChange = this.onSearchTextChange.bind(this);
  }

  handleSelect = tab => {
    this.setState({ searchText: '' });
    this.setState({ tab });
  };

  getStyle = tabName => {
    let { tab } = this.state;
    return {
      borderColor: tabName === tab ? '#042E60' : 'white',
      borderBottomWidth: 2,
      color: tabName === tab ? '#042E60' : '#8E8E8E',
      fontWeight: tabName === tab ? 'bold' : 'normal',
      fontSize: '14px',
    };
  };

  setAvailabilityPopup = () => {
    let { providedServices, openDateSelection } = this.props;
    ifTrainingsPublished(providedServices) && openDateSelection();
  };

  setNewTrainingPopup = (value = true) => {
    this.setState({ newTrainingPopup: value });
  };

  onSearchTextChange = e => {
    let { getBookings } = this.props;
    let delay = null;
    this.setState({ searchText: e.target.value });
    clearTimeout(delay);
    delay = setTimeout(() => getBookings( 1, this.state.searchText ), 1200);
  };

  render() {
    let { tab, searchText, newTrainingPopup } = this.state;
    let {
      currentUser,
      providedServices,
      providedServicesPagy,
      dateSelectionModal,
      selectedTrainingType,
    } = this.props;
    return (
      <div className="bg-main-background">
        <div className="flex flex-col px-0">
          <div className="m-1 px-3">
            <AthleteInfo
              currentUser={currentUser}
              history={null}
              createAvailability={this.setAvailabilityPopup}
              createTraining={this.setNewTrainingPopup}
              bookingLinkOnly
              inviteOnly
            />
          </div>

          {dateSelectionModal && (
            <DateSelection
              {...this.props}
              closeModal={() => this.props.closeDateSelection()}
              providedServices={getTrainingTypes(providedServices)}
              trainings={providedServices}
              trainingsPagy={providedServicesPagy}
              getBulkTrainings={() => {}}
              selectedTrainingType={selectedTrainingType}
            />
          )}

          {newTrainingPopup && (
            <NewTraining
              {...this.props}
              closePopup={choice => {
                this.setNewTrainingPopup(null);
                if (choice && choice.showAvailabilityPopup) this.setAvailabilityPopup();
              }}
            />
          )}

          <div className={`col-md-11 self-center py-2 ${dateSelectionModal && 'hidden'}`}>
            <div className="w-full flex flex-wrap justify-between rounded-lg overflow-hidden pl-2 bg-white shadow-sm">
              <div className="flex w-full md:w-1/4 font-sf-regular h-10">
                <button
                  onClick={() => this.handleSelect('history')}
                  style={this.getStyle('history')}
                  className="w-1/2 md:my-0 focus:outline-none font-sf-regular">
                  Booking History
                </button>

                <button
                  onClick={() => this.handleSelect('rejected')}
                  style={this.getStyle('rejected')}
                  className="text-xs w-1/2 md:my-0 focus:outline-none font-sf-regular">
                  Rejected Bookings
                </button>
              </div>

              <Search
                {...this.state}
                onChange={this.onSearchTextChange}
              />
            </div>

            {tab === 'history' ? (
              <div>
                <div className="desktop-view">
                  <Bookings searchText={searchText} showpagy {...this.props} />
                </div>

                <div className="hidden mini-view">
                  <BookingsMini searchText={searchText} {...this.props} />
                </div>
              </div>
            ) : (
              <div>
                <div className="desktop-view">
                  <RejectedBookings searchText={searchText} {...this.props} rejected={true} />
                </div>

                <div className="hidden mini-view">
                  <RejectedBookingsMini searchText={searchText} {...this.props} rejected={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(CalendarEntries(BookingsIndex));
