import React, { Component } from 'react';
import { formatDate, formatTime } from '../../../utils/utilities';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { BookingsActions } from '../../../store/actions';

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  rejectedBookings: state.Bookings.booking.get('rejectedBookings'),
  bookingRequestPagy: state.Bookings.booking.get('bookingRequestPagy'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

class RejectedBookingsMini extends Component {
  componentDidMount() {
    let { getRejectedBookings } = this.props;
    getRejectedBookings();
  }

  moveToDetail(id) {
    let { history } = this.props;
    history.push({
      pathname: `/bookings/${id}`,
    });
  }

  handlePageClick = data => {
    let { getRejectedBookings, history } = this.props;
    history.push(`/booking_requests?page=${data.selected + 1}`);
    getRejectedBookings(data.selected + 1);
  };

  render() {
    let { rejectedBookings, bookingRequestPagy, searchText } = this.props;

    let rejectedRequests =
      searchText !== ''
        ? rejectedBookings.filter(
            booking =>
              booking.attributes.client.data.attributes.contact_number
                .toLowerCase()
                .includes(searchText.toLowerCase()) && booking.attributes.status === 'rejected'
          )
        : rejectedBookings.filter(booking => booking.attributes.status === 'rejected');

    let filteredBookings = rejectedRequests;

    return (
      <div fluid="true" className="bg-main-background">
        <div>
          <div className={'mt-2'}>
            <div className="flex flex-col">
              {filteredBookings.length > 0 ? (
                filteredBookings.map((booking, index) => (
                  <div
                    key={index}
                    className="w-full bg-white mt-2 shadow-lg border-l-4 border-training-individual cursor-pointer rounded-lg"
                    onClick={() => this.moveToDetail(booking.id)}>
                    <div className="flex">
                      <div className="w-1/3 flex flex-col items-center justify-center content-center border-black b-r-1 my-3">
                        <small className="font-sf-regular text-black text-center text-xxs border-gray-400">
                          {formatDate(booking.attributes.booking_date)}
                        </small>

                        <small className="font-sf-regular text-black text-center text-xxs border-gray-400">
                          {formatTime(booking.attributes.from_time)}-
                          {formatTime(booking.attributes.to_time)}
                        </small>
                      </div>

                      <div className="w-full mx-2 relative flex flex-col justify-center py-3">
                        <small className="font-sf-semibold text-black text-left text-xxs border-gray-400 flex flex-col">
                          Location:{' '}
                          {booking.attributes.provided_service.data.attributes.training_type ===
                          'virtual'
                            ? 'Virtual training'
                            : booking.attributes.provided_service.data.attributes
                                .provided_service_locations.data[0].attributes.location &&
                              booking.attributes.provided_service.data.attributes
                                .provided_service_locations.data[0].attributes.location}
                        </small>

                        <small className="font-sf-regular text-black text-left text-xxs border-gray-400 py-1">
                          {booking.attributes.provided_service.data.attributes.name}
                        </small>

                        <small className="font-sf-regular text-black text-left text-xxs capitalize border-gray-400">
                          From: {booking.attributes.client.data.attributes.first_name}{' '}
                          {booking.attributes.client.data.attributes.last_name}
                        </small>

                        <div className="flex font-sf-semibold text-center justify-center items-center text-sm py-3 absolute right-0 self-center">
                          <small
                            className={`w-20 text-xxs text-center text-black capitalize font-sf-regular py-1 rounded-lg ${
                              booking.attributes.status === 'rejected'
                                ? 'bg-red-600 text-white '
                                : 'text-black'
                            }`}>
                            {booking.attributes.status}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h3 className={'font-sf-semibold text-center text-sm text-black'}>
                  No Rejected Bookings Available.
                </h3>
              )}
            </div>

            {bookingRequestPagy && (
              <div className="mt-4 text-primary-color">
                <ReactPaginate
                  previousLabel={'«'}
                  nextLabel={'»'}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={bookingRequestPagy.pages}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={'pagination'}
                  previousLinkClassName={'previous_page'}
                  nextLinkClassName={'next_page'}
                  disabledClassName={'disabled'}
                  activeClassName={'active'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(RejectedBookingsMini);
