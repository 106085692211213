import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import { Container } from 'react-bootstrap';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './store/index';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { icons } from './assets/icons';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

React.icons = icons;

const render = AppRoutes =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Container fluid="true" className="pl-0 pr-0 justify-content-center">
          <div>
            <ToastContainer
              position="top-center"
              autoClose={2000}
              draggable
              transition={Slide}
              hideProgressBar={true}
              newestOnTop
            />
            <AppRoutes />
          </div>
        </Container>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );

render(Routes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextApp = require('./routes').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
