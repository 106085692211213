import moment from 'moment';
import { toast } from 'react-toastify';
import { AsYouType } from 'libphonenumber-js';
import { trainingOption, bookingStatus, paymentStatus } from '../constants';
import { Placeholder } from '../assets/img';
import { CONSUMER_BASE_URL, CONSUMER_BASE_URL_STAGING } from '../constant';
import Geocode from 'react-geocode';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

export const deserializeData = (data) => {
  return new JSONAPIDeserializer({
    keyForAttribute: 'underscore_case'
  }).deserialize(data, (err, deserliazedData) => {
    return deserliazedData
  });
}

export const isLoggedIn = () => {
  const authToken = localStorage.getItem('auth_token');
  return authToken ? true : false;
};

export const isFreeTraining = (attributes) => {
  const price = +attributes?.price;

  return !price;
}

export const priceFormatter = number => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number);
};

export const upperCaseFirst = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getFileExtension = filename => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const formatDate = date => {
  let type = typeof date; // type check because 2 types of dates "2020-12-03T00:00:00.000Z" from the backend
  //and {object} from the date pickers.
  if (type === 'string') {
    let split = date && date.split('T');
    if (split.length > 0) {
      date = split[0];
      if (date[2] === '-') {
        return date;
      }

      return moment(date).format('MM-DD-YYYY');

    } else {
      return '';
    }
  } else {
    return moment(date).format('YYYY-MM-DD');
  }
};

export const formatTime = time => {
  return moment(time).utc().format('h:mm A');
};

export const slashedDate = (date) => {
  return new Date(formatDate(date).replace(/-/g, '/'))
}

export const convertDate = date => {
  return moment(date).format('MMM D');
};

export const getLink = url => {
  if (!url) return '';
  else if (url.includes('https://') || url.includes('http://')) return url;
  else return 'https://' + url;
};

export const isFutureTime = (date, time) => {
  let isAfter = moment(date + ' ' + time, 'YYYY-MM-DD h:mm').isAfter(moment());

  if (!isAfter) toast.warn('Please select times that have not happened yet today.');

  return isAfter;
};

export const isValidZip = zip => {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
};

export const isValidContactNumber = contactNumber => {
  let asYouType = new AsYouType();
  asYouType.defaultCountry = 'US';
  asYouType.reset();
  asYouType.input(contactNumber);
  return asYouType.getNumber() && asYouType.getNumber().isValid();
};

export const notify = (text, status = 'warn') => {
  const settings = {
    autoClose: 5000,
  };
  if (status === 'success') return toast.success(text, settings);
  return toast.warn(text, settings);
};

export const borderColor = trainingType => {
  return trainingType === trainingOption.GROUP.toLowerCase()
    ? 'border-training-group'
    : trainingType === trainingOption.INDIVIDUAL.toLowerCase()
    ? 'border-training-individual'
    : 'border-training-virtual';
};

export const backgroundColor = trainingType => {
  return trainingType === trainingOption.GROUP.toLowerCase()
    ? 'bg-training-group'
    : trainingType === trainingOption.INDIVIDUAL.toLowerCase()
    ? 'bg-training-individual'
    : 'bg-training-virtual';
};

export const isDateAfter = (fromDate, toDate) => {
  return moment(toDate).isAfter(fromDate);
};

export const isTimeAfter = (fromTime, toTime) => {
  return moment(toTime, 'h:mm').isAfter(moment(fromTime, 'h:mm'));
};

export const getRespectiveDay = item => {
  switch (item) {
    case '0':
      return 'Sun';
    case '1':
      return 'Mon';
    case '2':
      return 'Tues';
    case '3':
      return 'Wed';
    case '4':
      return 'Thurs';
    case '5':
      return 'Fri';
    case '6':
      return 'Sat';
    default:
      return ''
  }
};

export const getDays = selected => {
  return (
    selected &&
    selected.map(
      (item, index) => getRespectiveDay(item) + (selected.length - 1 !== index ? ', ' : '')
    )
  );
};

export const getDay = date => {
  return moment(date).utc().format('ddd');
};

export const toMinutes = time => {
  return moment.duration(time).asMinutes();
};

export const isTimeDurationEligible = (fromTime, toTime, selectedTrainings) => {
  let maxDuration = Math.max.apply(
    Math,
    selectedTrainings
      .filter(training => training.checked)
      .map(function (training) {
        console.log(training);
        console.log(training.duration);
        return training.duration;
      })
  );

  console.log(maxDuration);
  const timeDuration = moment
    .utc(moment(toTime, 'HH:mm:ss').diff(moment(fromTime, 'HH:mm:ss')))
    .format('HH:mm:ss');

  console.log('time durantion', timeDuration);
  const minutes = toMinutes(timeDuration);
  console.log(minutes);
  return minutes >= maxDuration;
};

export const ifTrainingsPublished = trainings => {
  return (
    trainings.filter(
      training => !training.attributes.deleted && training.attributes.status === 'published'
    ).length > 0
  );
};

export const getTrainingTypes = trainings => {
  return trainings.map(({ attributes }) => attributes.training_type);
};

export const typeThroughColor = color => {
  return color === 'virtual_color'
    ? 'Virtual'
    : color === 'individual_color'
    ? 'Individual'
    : 'Group';
};

export const includedTrainings = (trainings, included) => {
  return trainings.filter(training => {
    return included && included.includes(parseInt(training.id));
  });
};

export const getSocialUrl = title => {
  switch (title) {
    case 'facebook':
      return 'https://facebook.com/';
    case 'twitter':
      return 'https://twitter.com/';
    case 'instagram':
      return 'https://instagram.com/';
    case 'tiktok':
      return 'https://tiktok.com/';
    case 'website':
      return '';
    default:
        return '';
  }
};

export const validateSocialHandles = username => {
  return username === '' ? true : /^[a-zA-Z0-9._]+$/.test(username);
};

export const getPositionLabels = sportPositions => {
  return (
    sportPositions &&
    sportPositions.attributes.positions.map(({ name, display_name }) => ({
      label: `${name} (${display_name})`,
      value: display_name,
    }))
  );
};

export const getAthleteImageSrc = athlete =>
  athlete.profile_image ? athlete.profile_image : Placeholder;

export const removeSpecialCharacters = word => {
  return word.replace(/[^a-zA-Z0-9]/g, '');
};

export const findSportName = (id, sports) => {
  let sport = sports.find(sport => parseInt(sport.value) === id);
  return sport ? sport : { label: '', value: null };
};

export const ifTrainingHasLocation = (trainingType, locations) => {
  return trainingType === trainingOption.VIRTUAL.toLowerCase()
    ? true
    : locations.data && locations.data.length > 0
    ? true
    : false;
};

export const timeZoneOffset = () => {
  return new Date().getTimezoneOffset();
};

export const getTrainingColor = trainingType => {
  return trainingType && trainingType.toLowerCase() === trainingOption.VIRTUAL.toLowerCase()
    ? 'virtual_color'
    : trainingType.toLowerCase() === trainingOption.INDIVIDUAL.toLowerCase()
    ? 'individual_color'
    : 'group_color';
};

export const getTrainingType = trainingType => {
  return trainingType && trainingType.toLowerCase() === trainingOption.VIRTUAL.toLowerCase()
    ? 1
    : trainingType.toLowerCase() === trainingOption.INDIVIDUAL.toLowerCase()
    ? 2
    : 3;
};

export const getConsumerUrl = () => {
  const playerUrl = process.env.NODE_ENV === 'production' ? CONSUMER_BASE_URL : CONSUMER_BASE_URL_STAGING;

  return playerUrl
};

export const getBookingStatus = (booking, payment) => {
  let status = '';
  if (booking === bookingStatus.INCOMPLETE) status = 'Upcoming';
  else if (booking === bookingStatus.COMPLETED && payment === paymentStatus.PAID)
    status = 'Confirm';
  else if (booking === bookingStatus.COMPLETED && payment === paymentStatus.ACCEPTED)
    status = 'Completed';
  else status = booking;

  return status;
};

export const getPaymentStatus = (booking, payment) => {
  let status = '';
  if (booking === bookingStatus.COMPLETED && payment === paymentStatus.PAID) status = 'Review';
  else if (booking === bookingStatus.COMPLETED && payment === paymentStatus.ACCEPTED)
    status = 'Sent';
  else status = '--';

  return status;
};

export const secureBankField = field => {
  const last4Digits = field.slice(field.length - 4, field.length);
  const hideNumbers = field.slice(0, field.length - 4).replace(/[0-9]/g, '*');

  return `${hideNumbers}${last4Digits}`;
};

export const isTrainingLocationPresent = training => {
  return (
    (training.training_type !== 'virtual' &&
      training.provided_service_locations.data.length === 0) ||
    (training.training_type === 'virtual' && training.connection_detail === null)
  );
};

export const appendSymInStart = (text, sym) =>
  text[0] !== sym && text !== '' ? `${sym}${text}` : text;

export const getLatLng = address => {
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);

  return Geocode.fromAddress(address ? address : '').then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        return { lat, lng };
      },
      error => {
        console.error(error);
      }
  );
};

export function downloadAsCsv(filename, dataAsString) {
  const blob = new Blob([dataAsString]);
  if (window.navigator.msSaveOrOpenBlob){
    window.navigator.msSaveBlob(blob, filename);
  }
  else {
    const a = window.document.createElement("a");

    a.href = window.URL.createObjectURL(blob, {
      type: "text/plain"
    });
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
