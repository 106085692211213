import api from '../api';
import { client } from '../index';
import { showHUD, hideHUD, refreshAthlete } from '../User/actions';
import qs from 'qs';
import {deserializeData, downloadAsCsv, formatDate, notify} from '../../utils/utilities';

export const getBookings =
  (page = 1, searchQuery) =>
  async dispatch => {
    let payload = {
      page,
      per_page: 10,
      filter: { upcoming: true, completed: true },
      q: {
        status_not_eq: 3,
        provided_service_name_or_client_first_name_or_client_last_name_cont: searchQuery
      },
    };

    let paramsString = qs.stringify(payload);
    dispatch(showHUD());
    const { data } = await client().get(`${api.bookings()}?${paramsString}`);

    dispatch(hideHUD());
    dispatch(refreshAthlete());
    dispatch({ type: 'SET_BOOKINGS', payload: { data: data.data } });
    dispatch({ type: 'SET_BOOKINGS_PAGY', payload: { data: data.pagy } });
  };

export const getSpecificBooking = payload => async dispatch => {
  let paramsString = qs.stringify(payload);
  dispatch(showHUD());
  const { data } = await client().get(`${api.bookings()}?${paramsString}`);
  dispatch(hideHUD());

  return data;
};

export const getSingleBooking = id => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().get(`${api.bookings()}/${id}`);

  dispatch(hideHUD());
  dispatch({ type: 'SET_SINGLE_BOOKING', payload: { data: data.data } });

  return data.data;
};

export const updateStatus = (id, reject = null, fetchBooking) => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().put(`${api.bookings()}/${id}`, {
    booking: {
      reject,
    },
  });
  dispatch(hideHUD());

  if (data.data) {
    if (reject === true) dispatch(refundCharge(data.data.attributes.payment.data.id));
    dispatch(getSingleBooking(id));
    dispatch(refreshAthlete());
    dispatch({ type: 'UPDATE_BOOKING', payload: { data: data.data, id: id } });
  }
  return data;
};

export const getRejectedBookings =
  (page = 1) =>
  async dispatch => {
    let payload = {
      page,
      per_page: 10,
      q: { status_eq: 3 },
    };

    let paramsString = qs.stringify(payload);
    dispatch(showHUD());
    const { data } = await client().get(`${api.bookings()}?${paramsString}`);
    dispatch(hideHUD());
    dispatch(refreshAthlete());
    dispatch({ type: 'SET_BOOKING_REQUESTS', payload: { data: data.data } });
    dispatch({ type: 'SET_BOOKING_REQUEST_PAGY', payload: { data: data.pagy } });
  };

export const getSingleRequest = id => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().get(`${api.requests()}/${id}`);
  dispatch(hideHUD());
  dispatch({ type: 'SET_SINGLE_REQUEST', payload: { data: data.data } });
};

export const rejectRequest = (id, status, fetchBooking) => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().put(`${api.requests()}/${id}`, {
    booking_request: {
      status,
    },
  });
  dispatch(hideHUD());
  dispatch(getSingleRequest(id));
  dispatch({ type: 'UPDATE_REQUEST', payload: { data: data.data, id } });
};

export const refundCharge = id => async dispatch => {
  await client().put(`${api.payments()}/${id}/refund_charge`);
};

export const acceptRequest = (payload, id) => async dispatch => {
  await client().post(`${api.bookings()}?booking_request_id=${id}`, payload);
  dispatch(getSingleRequest(id));
  dispatch({ type: 'FILTER_REQUEST', payload: { id } });
};

//======================Payments============================
export const getPayments =
  (page = 1) =>
  async dispatch => {
    let payload = {
      page,
      per_page: 10,
    };

    let paramsString = qs.stringify(payload);
    dispatch(showHUD());
    const { data } = await client().get(`${api.payments()}?${paramsString}`);
    dispatch(hideHUD());
    dispatch({ type: 'SET_PAYMENTS', payload: { data: data.data } });
    dispatch({ type: 'SET_PAYMENTS_PAGY', payload: { data: data.pagy } });
  };

export const getSinglePayment = id => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().get(`${api.bookings()}/${id}`);

  dispatch(hideHUD());
  dispatch({ type: 'SET_SINGLE_PAYMENT', payload: { data: data.data } });
};

export const changeAthleteStatus = id => async dispatch => {
  let payload = {
    booking: {
      completed_by_athlete: true,
    },
  };
  await client().put(`${api.bookings()}/${id}`, payload);
  notify('Status updated successfully', 'success');
};

export const transferCharge = (id, accountID) => async dispatch => {
  let payload = {
    payment: {
      account_id: accountID,
    },
  };
  let data = await client().post(api.transferCharge(id), payload);
  dispatch(refreshAthlete());
  return data;
};

export const getAwaitingPayments = (trainingId) => (dispatch) => {
  return client().get(api.awaitingPayments(trainingId))
    .then((response) => deserializeData(response.data))
    .then((data) => {
      dispatch({ type: 'AWAITING_PAYMENTS_LOADED', payload: { data: data, trainingId } });

      return data;
    })
}

export const confirmAllPayments = async (trainingId) => {
  const data = await client().post(api.confirmAllPayments, {
    training_id: trainingId
  });
  // dispatch({ type: 'PAYMENTS_CONFIRMED', payload: { data: data, trainingId } });

  return data.data;
}

export const exportTrainingDataAsCsv = (trainingName, trainingId) => {
  return client().get(api.exportEmails(trainingId))
    .then((data) => {
      const date = formatDate(new Date());
      const filename = `export_${date}_${trainingName}(${trainingId}).csv`;

      downloadAsCsv(filename, data.data);
    })
    .catch(({ request }) => {
      if (request.status === 422) {
        notify(request.response, 'success');

      } else {
        notify(request.response);
      }
    })

}
