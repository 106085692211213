import React from 'react';
import moment from 'moment';
import Select from 'react-select';

function TimePicker(props) {
  const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
    var timeValueIsEarlier = moment(timeValue, 'h:mmA').diff(moment(endLimit, 'h:mmA')) < 0;
    var timeValueIsLaterThanLastValue =
      lastValue === undefined
        ? true
        : moment(lastValue, 'h:mmA').diff(moment(timeValue, 'h:mmA')) < 0;
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
  };

  var timeValue = '12:00AM';
  var lastValue;
  var endLimit = '11:44PM';
  var step = 15;
  var options = [];

  options.push({ label: timeValue, value: timeValue });

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue;
    timeValue = moment(timeValue, 'h:mmA').add(step, 'minutes').format('h:mmA');
    options.push({ label: timeValue, value: timeValue });
  }

  let formattedValue = moment(props.value, 'hh:mm a').format('LT').replace(/\s+/g, '');
  let index = options.findIndex(time => time.value === formattedValue);

  return (
    <div>
      {props.name === 'From' ? (
        <Select
          components={{
            IndicatorSeparator: () => null,
          }}
          className="w-full text-primary-color focus:outline-none"
          isSearchable={false}
          required
          defaultValue={options[index]}
          onChange={props.onChange}
          options={options}
          id="From"
          key={props.name}
          value={options[index]}
          maxMenuHeight={120}
        />
      ) : (
        <Select
          components={{
            IndicatorSeparator: () => null,
          }}
          className="w-full text-primary-color focus:outline-none"
          isSearchable={false}
          required
          defaultValue={options[index]}
          onChange={props.onChange}
          options={options}
          id="To"
          key={props.name}
          value={options[index]}
          maxMenuHeight={120}
        />
      )}
    </div>
  );
}

export default TimePicker;
