import React, { Component } from 'react';
import { Form, Card } from 'react-bootstrap';
import { AccountInfo, TrainerInfo, AddSocials, ReviewStep } from './index';
import { connect } from 'react-redux';
import {
  UserActions,
  ServiceActions,
  BookingsActions,
  CalendarActions,
} from '../../../store/actions';
import Geocode from 'react-geocode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placeholder } from '../../../assets/img';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  getLink,
  isValidZip,
  notify,
  getPositionLabels,
} from '../../../utils/utilities';
import { CreateTraining, CreateAvailability } from '../../Shared';
import CalendarEntries from '../../../hocs/CalendarEntries';
import { availabilityStep, trainingStep, bankDetailsStep } from '../../../stringConstants';
import { AddCard } from '../ConnectAccount';
import ImageEdit from '../../Shared/ImageEdit';

let connectProps = {
  ...UserActions,
  ...ServiceActions,
  ...BookingsActions,
  ...CalendarActions,
};

let connectState = state => ({
  trainings: state.Service.service.get('services'),
  sports: state.User.current.get('sports'),
  sportPositions: state.User.current.get('sportPositions'),
  currentUser: state.User.current.get('currentUser'),
  bookings: state.Bookings.booking.get('bookings'),
  providedServices: state.Service.service.get('providedServices'),
  providedServicesPagy: state.Service.service.get('providedServicesPagy'),
  buttonLoading: state.User.meta.get('buttonLoading'),
  loader: state.User.meta.get('showHUD'),
  services: state.Service.service.get('services'),
  selectedTrainingType: state.User.meta.get('selectedTrainingType'),
  availabilities: state.Calendar.calendar,
  availabilityPagy: state.Calendar.calendar,
  invitedById: state.User.meta.get('invitedById'),
  inviter: state.User.meta.get('inviter')
});

let enhancer = connect(connectState, connectProps);

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);

const ProgressCircle = props => {
  return (
    <div className="flex-1">
      <div
        className={`w-4 h-4 ${props.fill ? 'bg-primary-color' : 'bg-gray-300 border border-gray-700'
          }  mx-auto rounded-full flex items-center`}
      />
    </div>
  );
};

const ProgressBar = props => {
  return (
    <div className={`w-full align-center items-center align-middle content-center flex`}>
      <div
        className={`w-full ${props.fill ? 'bg-primary-color' : 'bg-gray-300'
          } items-center align-middle align-center flex-1`}>
        <div className="text-xs leading-none h-1 text-center text-grey-darkest rounded" />
      </div>
    </div>
  );
};

class SignupWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,

      firstName: '',
      lastName: '',
      email: '',
      gender: null,
      phoneNumber: '',
      dob: '',
      invalidDate: false,
      password: '',
      passwordConfirmation: '',

      school: null,
      college: null,
      sportId: null,
      type: '',
      positionFirst: null,
      positionSecond: null,
      positionThird: null,
      passingYear: null,
      profileImage: '',
      profileFile: null,
      jerseyNumber: '',

      website: '',
      instagram: '',
      facebook: '',
      twitter: '',
      tiktok: '',
      about: '',
      errorText: '',

      loader: false,
      authToken: '',
      trainingNameOB: '',

      currentLocation: '',
      currentLocationData: null,
      hometownLocation: '',
      hometownLocationData: null,
      timeZone: 'America/New_York',
    };
  }

  componentDidMount() {
    const { getSports } = this.props;

    this.inviteCodeGateKeeper();

    getSports();
  }

  inviteCodeGateKeeper() {
    if (this.props.inviter === null) {
      this.props.history.replace('/');
    }
  }

  getPositions = () => {
    let { positionFirst, positionSecond, positionThird } = this.state;
    let positions = [];
    if (positionFirst !== null) positions = [...positions, positionFirst.value];
    if (positionSecond !== null) positions = [...positions, positionSecond.value];
    if (positionThird !== null) positions = [...positions, positionThird.value];

    const uniquePositions = [...new Set(positions)];
    return uniquePositions;
  };

  submitSignup = async e => {
    e.preventDefault();
    const { signUp, getProfileSignedUrl, uploadImage, invitedById, inviter } = this.props;
    if (inviter === null) {
      alert('No inviter? How did you get here?');
      return;
    };

    console.log(inviter)

    let {
      about,
      dob,
      email,
      facebook,
      firstName,
      gender,
      instagram,
      lastName,
      passingYear,
      password,
      passwordConfirmation,
      phoneNumber,
      profileFile,
      profileImage,
      club,
      group,
      school,
      college,
      sportId,
      step,
      tiktok,
      twitter,
      type,
      website,
      jerseyNumber,
      hometownLocation,
      currentLocationData,
      hometownLocationData,
      positionFirst,
      positionSecond,
      positionThird,
      timeZone,
    } = this.state;
    if (step >= 4) return;

    if (step === 1) {
      const { checkValidity } = this.props;
      // const contact = await checkValidity({ contact_number: phoneNumber });
      const emailValidity = await checkValidity({ email });

      // else if (contact.result === true)
      //   notify('Contact number already taken. Try with another one.');
      if (!phoneNumber) {
        notify('Invalid contact number.');

      } else if (emailValidity && emailValidity.result === true)
        notify('Email already taken. Try with another one.');
      else if (!dob) notify('Select date of birth.');
      else if (!gender) notify('Select your gender.');
      else if (password !== passwordConfirmation || password.length < 7)
        notify('Password does not match or too short.');
      else this.changeContent();
    } else if (step === 2) {
      if (!(passingYear && passingYear.value >= 2021) && (!profileImage || !profileFile))
        notify('Upload a profile picture.');
      else if (!college) notify('Select a college first.');
      else if (!sportId) notify('Select Sport.');
      else if (!type) notify('Select Trainer Type.');
      else if (!positionFirst && !positionSecond && !positionThird) notify('Select position.');
      else if (currentLocationData === null || hometownLocationData === null)
        notify('Please select current and hometown location first.');
      else this.changeContent();
    } else if (step === 3) {
      this.setState({ loader: true });

      const payload = {
        email,
        first_name: firstName,
        last_name: lastName,
        contact_number: phoneNumber,
        password: password,
        password_confirmation: passwordConfirmation,
        gender: gender.value,
        dob: moment(dob).format('YYYY-MM-DD'),
        profile_description: about,
        willing_to_travel: false,
        high_school_id: school,
        college_id: college,
        athlete_type: type.value,
        sport_id: sportId.value,
        club,
        passing_year: passingYear ? passingYear.value : 2020,
        position: this.getPositions(),
        hometown: hometownLocation,
        city: currentLocationData.city,
        state: currentLocationData.state,
        postal_code: currentLocationData.zip,
        website: getLink(website),
        instagram,
        tiktok,
        twitter,
        facebook,
        jersey_number: jerseyNumber,
        invited_by_id: invitedById,
        invite_by_id: inviter?.id,
        invite_by_type: inviter?.type,
        time_zone: timeZone,
      };

      if (group) {
        if (isNaN(group)) {
          payload.group_name = group;

        } else {
          payload.group_id = group;
        }
      }

      if (profileFile) {
        const image = await getProfileSignedUrl();
        await uploadImage(profileFile, image.signed_url);
        payload.profile_image = image.url;
      }

      let userData = await signUp(payload);
      this.setState({ loader: false, authToken: userData && userData.attributes.auth_token });
      if (userData) this.changeContent();
    } else this.changeContent();
  };

  changeContent = () => {
    this.setState(prevState => ({ step: prevState.step + 1 }));
  };

  handleOnChange = (fieldName, value) => {
    if (fieldName === 'dob') {
      moment().diff(value, 'years') >= 15
        ? this.setState({ dob: value, invalidDate: false })
        : this.setState({ invalidDate: true }, () => {
          return;
        });
    } else if (fieldName === 'postalCode' || fieldName === 'hometownPostal') {
      this.setState({ [fieldName]: value });

      fieldName === 'postalCode'
        ? this.setState({ invalidZip: !isValidZip(value) })
        : this.setState({ invalidHometownZip: !isValidZip(value) });
    }
    this.setState({ [fieldName]: value });
  };

  getTypes = trainings => {
    return trainings.map(({ attributes }) => attributes.training_type);
  };

  pressSoundsGood = () => {
    let { authToken } = this.state;
    localStorage.setItem('auth_token', authToken);
    this.props.setDefaultTrainingType(null);
    this.props.history.push('/dashboard');
  };

  getHeader = step => {
    switch (step) {
      case 1:
        return 'Account Info';
      case 2:
        return 'Trainer Info';
      case 3:
        return 'Social Info';
      case 4:
        return 'Create Training Option';
      case 5:
        return 'Set Your Availability';
      case 6:
        return 'Trainer Details';
      case 7:
        return 'Account Details';
      default:
        return '';
    }
  };

  getSchoolsBySearch = async search => {
    let data = await this.props.getSchoolsBySearch({ q: { nickname_or_city_start: search } });
    return data;
  };

  getCollegesBySearch = async search => {
    let data = await this.props.getCollegesBySearch({ q: { nickname_or_city_start: search } });
    return data;
  };

  getSportPositions = async id => {
    await this.props.getSportPositions(id);
  };

  resetPositions = () => {
    this.setState({ positionFirst: null, positionSecond: null, positionThird: null });
  };

  render() {
    const {
      about,
      dob,
      email,
      facebook,
      firstName,
      gender,
      instagram,
      invalidDate,
      lastName,
      loader,
      club,
      passingYear,
      password,
      passwordConfirmation,
      phoneNumber,
      profileImage,
      school,
      college,
      step,
      type,
      sportId,
      tiktok,
      trainingNameOB,
      twitter,
      website,
      jerseyNumber,
      errorText,
      hometownLocation,
      currentLocation,
      positionFirst,
      positionSecond,
      positionThird,
      timeZone,
    } = this.state;

    const {
      availabilities,
      currentUser,
      history,
      providedServices,
      providedServicesPagy,
      sports,
      trainings,
      selectedTrainingType,
      sportPositions,
    } = this.props;

    const filteredTrainings =
      providedServices && providedServices.filter(item => !item.attributes.deleted);
    const firstAvailability = availabilities.toJS().availabilities || [];

    return (
      <div className="min-h-screen justify-center items-center overflow-y-scroll fixed inset-0 z-50 py-5 bg-main-background no-scrollbar">
        <div
          className="overflow-hidden flex items-center justify-center mb-56"
          style={{ minHeight: '500px', minWidth: '350px' }}>
          <div
            className="justify-center items-center flex flex-col overflow-y-scroll w-full md:w-1/2 mb-3 bg-white shadow-md"
            style={{ borderRadius: 20 }}>
            <div className="pt-3 w-full hide-top-bar">
              <div className="flex pb-1 px-5">
                <ProgressCircle fill />

                <ProgressBar fill={step >= 2} />

                <ProgressCircle fill={step >= 2} />

                <ProgressBar fill={step >= 3} />

                <ProgressCircle fill={step >= 3} />

                <ProgressBar fill={step >= 4} />

                <ProgressCircle fill={step >= 4} />

                <ProgressBar fill={step >= 5} />

                <ProgressCircle fill={step >= 5} />

                <ProgressBar fill={step >= 6} />

                <ProgressCircle fill={step >= 6} />

                <ProgressBar fill={step >= 7} />

                <ProgressCircle fill={step >= 7} />
              </div>

              <div className="flex text-xs content-center text-center primary-text-regular">
                <div className={`w-1/4 text-xs text-primary-color`}>Account Info</div>

                <div
                  className={`w-1/4 text-xs ${step >= 2 ? 'text-primary-color' : 'text-gray-500'}`}>
                  Trainer Info
                </div>

                <div
                  className={`w-1/4 text-xs ${step >= 3 ? 'text-primary-color' : 'text-gray-500'}`}>
                  Social Info
                </div>

                <div
                  className={`w-1/4 text-xs ${step >= 4 ? 'text-primary-color' : 'text-gray-500'}`}>
                  Training
                </div>

                <div
                  className={`w-1/4 text-xs ${step >= 5 ? 'text-primary-color' : 'text-gray-500'}`}>
                  Availability
                </div>

                <div
                  className={`w-1/4 text-xs ${step >= 6 ? 'text-primary-color' : 'text-gray-500'}`}>
                  Review
                </div>

                <div
                  className={`w-1/4 text-xs ${step >= 6 ? 'text-primary-color' : 'text-gray-500'}`}>
                  Bank Account
                </div>
              </div>
            </div>

            <h1 style={{ fontSize: 21 }} className="font-sf-regular pt-4 text-black">
              {this.getHeader(step)}
            </h1>

            <span className="font-sf-regular show-step-count text-xs text-black">{`(Step ${step} of 7)`}</span>

            <div className="w-full">
              <Form autoComplete="off" onSubmit={this.submitSignup}>
                <div>
                  <div className="p-1 w-full items-center flex justify-center overflow-y-scroll">
                    {step === 1 && (
                      <Card
                        style={{ border: 'none' }}
                        className="w-full text-center add-horizontal-padding remove-horizontal-padding">
                        <Card.Body>
                          <AccountInfo
                            email={email}
                            password={password}
                            firstName={firstName}
                            lastName={lastName}
                            passwordConfirmation={passwordConfirmation}
                            handleOnChange={this.handleOnChange}
                            gender={gender}
                            history={history}
                            dob={dob}
                            invalidDate={invalidDate}
                            phoneNumber={phoneNumber}
                            classPassed={passingYear}
                          />
                        </Card.Body>
                      </Card>
                    )}

                    {step === 2 && (
                      <Card
                        className="w-full add-horizontal-padding remove-horizontal-padding"
                        style={{ border: 'none', backgroundColor: 'white' }}>
                        <Card.Title className="mt-2 flex">
                          <div
                            onClick={() => {
                              this.setState({ step: step - 1 });
                            }}
                            className="flex flex-col ">
                            <div className="cursor-pointer rounded hover:bg-blue-700 text-white w-full text-xs py-1 px-2 font-normal capitalize bg-primary-color">
                              <FontAwesomeIcon icon={faBackward} style={{ color: 'white' }} />
                            </div>
                          </div>
                        </Card.Title>

                        <div className="items-center justify-center content-center flex flex-col">
                          <div
                            style={{
                              height: '80px',
                              width: '80px',
                              borderRadius: 100,
                              backgroundColor: college ? college.color : '#D7DAE2',
                            }}
                            className="object-cover overflow-hidden">
                            <img
                              className="object-cover rounded-full h-full w-full"
                              alt=""
                              src={profileImage ? profileImage : Placeholder}
                            />
                          </div>

                          <ImageEdit
                            afterEditDone={(image, imageFile) => {
                              this.setState({ profileImage: image, profileFile: imageFile });
                            }}
                          />
                        </div>

                        <Card.Body>
                          <TrainerInfo
                            services={trainings}
                            loader={loader}
                            sports={sports}
                            school={school}
                            college={college}
                            club={club}
                            getSchools={this.props.getSchoolsBySearch}
                            getColleges={this.props.getCollegesBySearch}
                            handleOnChange={this.handleOnChange}
                            getSportPositions={this.getSportPositions}
                            sportId={sportId}
                            history={history}
                            type={type}
                            about={about}
                            jerseyNumber={jerseyNumber}
                            sportPositions={getPositionLabels(sportPositions)}
                            resetPositions={this.resetPositions}
                            currentLocation={currentLocation}
                            positionFirst={positionFirst}
                            positionSecond={positionSecond}
                            positionThird={positionThird}
                            setCurrentLocation={value => this.setState({ currentLocation: value })}
                            setCurrentLocationData={value =>
                              this.setState({ currentLocationData: value })
                            }
                            hometownLocation={hometownLocation}
                            setHomeTownLocation={value =>
                              this.setState({ hometownLocation: value })
                            }
                            setHomeTownLocationData={value =>
                              this.setState({ hometownLocationData: value })
                            }
                            timeZone={timeZone}
                          />
                        </Card.Body>
                      </Card>
                    )}

                    {step === 3 && (
                      <Card
                        className="w-full text-center bg-transparent add-horizontal-padding remove-horizontal-padding"
                        style={{ border: 'none' }}>
                        <Card.Title className="mt-4 flex">
                          <div
                            onClick={() => {
                              this.setState({ step: 2 });
                            }}
                            className="flex flex-col ">
                            <div className=" cursor-pointer rounded bg-primary-color hover:bg-blue-700 text-white w-full text-xs py-1 px-2 font-normal capitalize">
                              <FontAwesomeIcon icon={faBackward} style={{ color: 'white' }} />
                            </div>
                          </div>
                        </Card.Title>

                        <Card.Body>
                          <AddSocials
                            handleOnChange={this.handleOnChange}
                            website={website}
                            instagram={instagram}
                            facebook={facebook}
                            twitter={twitter}
                            tiktok={tiktok}
                            loader={loader}
                            history={history}
                            errorText={errorText}
                            setError={value => {
                              this.setState({ errorText: value });
                            }}
                          />
                        </Card.Body>
                      </Card>
                    )}

                    {step === 4 && (
                      <div className="w-full flex flex-col items-center overflow-y-scroll self-center add-horizontal-padding remove-horizontal-padding">
                        <div className="pb-2 mt-4 text-center">
                          <small className="font-sf-regular text-center text-gray-600 text-sm">
                            {trainingStep}
                          </small>
                        </div>

                        <CreateTraining
                          {...this.props}
                          onboarding
                          changeContent={this.changeContent}
                          changeTrainingNameOB={name => {
                            this.setState({ trainingNameOB: name });
                          }}
                        />
                      </div>
                    )}

                    {step === 5 && (
                      <div className="w-full">
                        <div className="pb-2 text-center mt-4 px-10">
                          <small className="font-sf-regular text-center text-gray-600 text-sm py-1">
                            {`${availabilityStep(trainingNameOB)}`}
                          </small>
                        </div>

                        <CreateAvailability
                          {...this.props}
                          providedServices={this.getTypes(filteredTrainings)}
                          trainings={filteredTrainings}
                          trainingsPagy={providedServicesPagy}
                          changeContent={this.changeContent}
                          selectedTrainingType={selectedTrainingType}
                          onboarding
                        />
                      </div>
                    )}

                    {step === 6 && (
                      <ReviewStep
                        selectedTrainingType={selectedTrainingType}
                        trainings={filteredTrainings}
                        firstAvailability={firstAvailability}
                        currentUser={currentUser}
                        history={history}
                        changeContent={this.changeContent}
                      />
                    )}

                    {step === 7 && (
                      <div>
                        <div className="pb-2 mt-4 text-center">
                          <small className="font-sf-regular text-center text-gray-600 text-sm">
                            {bankDetailsStep}
                          </small>
                        </div>

                        <AddCard onboarding pressSoundsGood={this.pressSoundsGood} />
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default enhancer(CalendarEntries(SignupWizard));
